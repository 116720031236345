import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "./../../../environments/environment";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectManagementService {
  public pmsCount = new Subject<number>();

  // Create an observable to watch the subject and send out a stream of updates (You will subscribe to this to get the update stream)
  public pmsCount$ = this.pmsCount.asObservable(); //Has a $

  // ------ How to update the subject ---------
  // Create a method that allows you to update the subject being watched by observable
  public updatePmsCount(newpmsCount: number) {
    this.pmsCount.next(newpmsCount);
  }
  // data:any={};

  constructor(private http: HttpClient) { }

  GetAllRisks(page?:any,limit?:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/risk?page=${page}&limit=${limit}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetGanttData(data:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/get-gantt-chart-data`;
      this.http
        .post(apiURL,data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAllRisksByProjectId(iProjectId:any,page?:any,limit?:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/risk-by-project?iProjectId=${iProjectId}&page=${page}&limit=${limit}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  GetRiskById(riskId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/risk-by-id/${riskId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  AddRisk(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/partner/v2/risk`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  EditRisk(id: any, data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/update-risk/${id}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  DeleteRisk(riskId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/delete-risk/${riskId}`;
      this.http
        .put(apiURL, '')
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetShadowResourceByAssignee(iProjectResourceId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/get-shadow-list-by-assignee?iProjectResourceId=${iProjectResourceId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  DeleteShadowAssignee(iShadowResourceId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/delete-shadow/${iShadowResourceId}`;
      this.http
        .put(apiURL, '')
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  DeleteTaskComment(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/delete-pms-task-comment`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  EditMitigationPlan(id: any, data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/update-mitigation/${id}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetAllProjects() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/project-names`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAllTasks(searchValue: any,page:any,limit:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/get-task-management?searchValue=${searchValue}&page=${page}&limit=${limit}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAllEscalation(page?:any,limit?:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/escalation?page=${page}&limit=${limit}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise; 
  }

  GetAllEscalationByProjectId(iProjectId:any,page?:any,limit?:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/escalation-by-project?iProjectId=${iProjectId}&page=${page}&limit=${limit}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
  GetEscalationById(projectId: any, escalationId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/escalation-by-id?iProjectId=${projectId}&iEscalationManagementId=${escalationId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  AddEscalation(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/partner/v2/create-escalation`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  EditEscalation(id: any, data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/update-escalation/${id}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  DeleteEscalation(escalationId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/delete-escalation/${escalationId}`;
      this.http
        .put(apiURL, '')
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  AddEscalationComment(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/common/v2/add-comment`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  EditGanttChartLink(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/partner/v2/update-gantt-chart-link`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  RemoveGanttChartLink(data:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/partner/v2/remove-gantt-chart-link`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }
  
  AddTaskComment(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/common/v2/add-pms-task-comment`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetAllEscalationComments(projectId: any, escalationId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/get-comments?iProjectId=${projectId}&iEscalationManagementId=${escalationId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAllTaskComments(projectId: any, subTask: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/get-pms-task-comment?iProjectId=${projectId}&iSubTaskId=${subTask}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  // updateData(newValue: any) {
  //   this.data.projectId = newValue.projectId?newValue.projectId:"";
  //   this.data.milestoneId = newValue.milestoneId?newValue.milestoneId:"";
  //   this.data.taskId = newValue.taskId?newValue.taskId:"";
  //   let temp=JSON.stringify(this.data);
  //   localStorage.setItem("partner_misc_data",temp);
  // }

  CreateProject(data: any) {
    // console.log(data, ">>>data.");

    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/create-project`;
      // create a FormData object
      const formData = new FormData();

      // append the assigneeDetails to the FormData object

      // formData.append('json', JSON.stringify({ key: 'value' }));


      formData.append('assigneeDetails', JSON.stringify(data.assigneeDetails));
      formData.append('milestoneDetails', JSON.stringify(data.milestoneDetails));
      formData.append('iProjectEndDate', data.iProjectEndDate);
      formData.append('iProjectStartDate', data.iProjectStartDate);
      formData.append('iProjectStatus', data.iProjectStatus);
      formData.append('txProjectDescription', data.txProjectDescription);
      formData.append('vProjectName', data.vProjectName);
      formData.append('vContractCode', data.vContractCode);
      formData.append('stackHolderArray', JSON.stringify(data.stackHolderArray));
      formData.append('iClientId', data.iClientId);

      


      this.http
        .post(apiURL, formData)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  FileUploadForCreateProject(data: any, id: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/attachment-upload/${id}`;

      const files = [...data.vProjectAttachment];

      // console.log(files, "files");


      // create a FormData object
      const formData = new FormData();


      // Append each file to the FormData object with the key "vProjectAttachment[]"
      for (let i = 0; i < files.length; i++) {
        formData.append(`vProjectAttachment${i}`, files[i]);
      }
      formData.append("tifileLocation", data.tifileLocation);
      formData.append("vSubFolderPath", data.vSubFolderPath);

      this.http
        .put(apiURL, formData)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  GetAllResource() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/get-allresource`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  GetProjectDetails(project_id: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/get-project/${project_id}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetProject(selectedFilter: any, searchValue: any,page?:any,limit?:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/get-projects?filterFlag=${selectedFilter}&searchValue=${searchValue}&page=${page}&limit=${limit}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetProjectCount() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/getProjectStatusCount`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAllMilestones(project_id: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/milestone/${project_id}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetMilestoneDetails(milestone_id: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/get-milestone/${milestone_id}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAllClient() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/getAllClient`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UpdateClient(project_id: any, data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/update-project-client/${project_id}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  DeleteAttachment(iFileStorageId: any, project_id?: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/delete-file/${iFileStorageId}?id=${project_id}`;
      this.http
        .put(apiURL, '')
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UpdateProjectDetails(project_id: any, data: any) {
    // create a FormData object
    const formData = new FormData();
    formData.append('iProjectEndDate', data.iProjectEndDate);
    formData.append('iProjectStartDate', data.iProjectStartDate);
    formData.append('iProjectStatus', data.iProjectStatus);
    formData.append('txProjectDescription', data.txProjectDescription);
    formData.append('vProjectName', data.vProjectName);
    formData.append('vContractCode', data.vContractCode);
    formData.append('iProjectDeliveryDate', data.iProjectDeliveryDate);
    formData.append('iCompletionDate', data.iCompletionDate);


    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/update-project/${project_id}`;
      this.http
        .put(apiURL, formData)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  FileUploadForUpdateProject(data: any, id: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/attachment-upload/${id}`;
      const files = [...data.vProjectAttachment];


      // create a FormData object
      const formData = new FormData();

      // Append each file to the FormData object with the key "vProjectAttachment[]"
      for (let i = 0; i < files.length; i++) {
        formData.append(`vProjectAttachment${i}`, files[i]);
      }
      formData.append("tifileLocation", data.tifileLocation);

      this.http
        .put(apiURL, formData)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  DeleteMilestone(milestone_id: any, data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/delete-milestone/${milestone_id}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  DeleteProject(project_id: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/delete-project/${project_id}`;
      this.http
        .put(apiURL, '')
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  RestoreProject(project_id: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/undo-project/${project_id}`;
      this.http
        .put(apiURL, '')
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  AddMilestone(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/milestone`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  UpdateMilestone(milestone_id: any, data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/update-milestone?iMilestoneId=${milestone_id}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  ChangeMilestoneOrder(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/update-milestone-order`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  AddProjectResource(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/project-resource`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  EditProjectResource(iProjectResourceId: any, data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/project-resource/${iProjectResourceId}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  AddValueAddition(data: any, id: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/createValueAddition/${id}`;

      const files = [...data.vProjectAttachment];

      // create a FormData object
      const formData = new FormData();

      // Append each file to the FormData object with the key "vProjectAttachment[]"
      for (let i = 0; i < files.length; i++) {
        formData.append(`vProjectAttachment${i}`, files[i]);
      }
      formData.append("tifileLocation", data.tifileLocation);
      formData.append("tiDisplayStatus", data.tiDisplayStatus);
      if(data.tiOpportunityStatus && data.vValueAdditionType=="Opportunity"){
        formData.append("tiOpportunityStatus", data.tiOpportunityStatus);
      }
      formData.append('vValueAdditionTitle', data.vValueAdditionTitle);
      formData.append('vValueAdditionType', data.vValueAdditionType);
      formData.append('txValueAdditionDescription', data.txValueAdditionDescription);
      formData.append('iProjectId', data.iProjectId);
      formData.append('vResponsible', JSON.stringify(data.vResponsiblePerson));

      this.http
        .post(apiURL, formData)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  UpdateValueAddition(data: any, id: any, project_id: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/updateValueAddition/${id}?iProjectId=${project_id}`;

      const files = [...data.vProjectAttachment];

      // create a FormData object
      const formData = new FormData();

      // Append each file to the FormData object with the key "vProjectAttachment[]"
      for (let i = 0; i < files.length; i++) {
        formData.append(`vProjectAttachment${i}`, files[i]);
      }
      formData.append("tifileLocation", data.tifileLocation);
      formData.append("tiDisplayStatus", data.tiDisplayStatus);
      if(data.tiOpportunityStatus && data.vValueAdditionType=="Opportunity"){
        formData.append("tiOpportunityStatus", data.tiOpportunityStatus);
      }

      
      formData.append('vValueAdditionTitle', data.vValueAdditionTitle);
      formData.append('vValueAdditionType', data.vValueAdditionType);
      formData.append('txValueAdditionDescription', data.txValueAdditionDescription);
      formData.append('iProjectId', data.iProjectId);
      if(data.vResponsiblePerson){formData.append('vResponsible', JSON.stringify(data.vResponsiblePerson));}
      

      this.http
        .put(apiURL, formData)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  GetValueAdditionbyId(id: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/value-addition/${id}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  GetAllProjectResources(project_id: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/project-resource/${project_id}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAllProjectShadowResources(projectResourceId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/project-shadow-resource/${projectResourceId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAttachments(project_id: any, tifileLocation: any, recentFilesData: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/get-files?iProjectId=${project_id}&tifileLocation=${tifileLocation}&recentFilesData=${recentFilesData}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetEmployeeLogs(project_id: any, milestone_id: any, subtask_id: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/get-logs?iProjectId=${project_id}&iMilestoneId=${milestone_id}&iSubTaskId=${subtask_id}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAllSubTask(project_id: any, iMilestoneId: any, searchValue: any, filterFlag: any, selectedAssignee: any, selectedFeatureId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/sub-task?searchValue=${searchValue}&filterFlag=${filterFlag}&iMilestoneId=${iMilestoneId}&iProjectId=${project_id}&iEmployeeId=${selectedAssignee}&iMasterFeatureId=${selectedFeatureId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  DeleteStackholder(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/delete-stackholder`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  AddSubTask(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/sub-task`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  EditSubTask(iSubTaskId: any, iMilestoneId: any, iProjectId: any, data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/update-sub-task?iSubTaskId=${iSubTaskId}&iMilestoneId=${iMilestoneId}&iProjectId=${iProjectId}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  DeleteSubTaskAssignee(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/delete-sub-task-resource`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  DeleteSubTask(iSubTaskId: any, iMilestoneId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/delete-sub-task?iSubTaskId=${iSubTaskId}&iMilestoneId=${iMilestoneId}`;
      this.http
        .put(apiURL, '')
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }



  GetAllValueAddition(page?:any,limit?:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/getAllValueAddition?page=${page}&limit=${limit}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAllValueAdditionByProjectId(iProjectId: any,page?:any,limit?:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/valueAddition-by-project?iProjectId=${iProjectId}&page=${page}&limit=${limit}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  DeleteProjectResource(resource_id: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/delete-project-resource/${resource_id}`;
      this.http
        .put(apiURL, '')
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }



  GetAllProjectFeedback(page?:any,limit?:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/getAllProjectFeedback?page=${page}&limit=${limit}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAllProjectFeedbackByProjectId(iProjectId:any,page?:any,limit?:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/Feedback-by-project?iProjectId=${iProjectId}&page=${page}&limit=${limit}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  DeleteProjectFeedback(id: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/deleteProjectFeedback?iProjectFeedbackId=${id}`;
      this.http
        .put(apiURL, '')
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  AddProjectFeedback(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/createProjectFeedback`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UpdateProjectFeedback(data: any, id: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/updateProjectFeedback?iProjectFeedbackId=${id}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAllFileStorageData(recentFilesData: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/getFilesByPartner?recentFilesData=${recentFilesData}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetFileStorageDatabyProject(iProjectId: any, recentFilesData: any, SearchValue: any, folderType: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/getAllFilesByProject?iProjectId=${iProjectId}&recentFilesData=${recentFilesData}&SearchValue=${SearchValue}&FolderwiseFile=${folderType}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }



  GetFileStorageCount() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/getFileStorageData`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }



  DeleteValueAddition(id: any, project_id: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/setStatus/${id}?iProjectId=${project_id}`;
      this.http
        .put(apiURL, '')
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  GetTaskOverviewMilestoneWise(project_id: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/get-completed-task?iProjectId=${project_id}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetTaskBasedManpowerOverview(project_id: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/manpower-overview?iProjectId=${project_id}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAllTasksByStatus(project_id: any, featureId:any, SprintId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/get-task-management-by-status?iProjectId=${project_id}&iMasterFeatureId=${featureId}&iSprintId=${SprintId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UpdateTaskByStatus(data: any, iProjectId : any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/subtask-order?iProjectId=${iProjectId}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  GetDeliverablesProgressOverview(projectId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/deliverables-progress-overview?iProjectId=${projectId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  GetTimelineChart(projectId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/timeline-chart?iProjectId=${projectId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetMilestoneProgressOverview(projectId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/getMilestoneProgressChartData?iProjectId=${projectId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetTotalContractedHours(projectId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/getAllMilestoneProgressChartData?iProjectId=${projectId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAllProjectsForFileStorage() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/file-storage-data`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }




ApprovalMultipleLogForShadowResource(data: any, iIsApproved: any) {
  const promise = new Promise((resolve, reject) => {
    let apiURL = environment.baseUrl + `/partner/v2/isAllShadowLogApproved?iIsApproved=${iIsApproved}`;
    this.http
      .put(apiURL, data)
      .toPromise()
      .then((res: any) => {
        // Success
        resolve(res);
      })
      .catch((err: any) => {
        console.log(err);
        reject(err);
      });
  });
  return promise;
}


  ApprovalMultipleLogForResource(data: any, iIsApproved: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/isAllApproved?iIsApproved=${iIsApproved}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  ApprovalIndividualLogForResource(iResourceLogId: any, iIsApproved: any, iEmployeeId: any, iProjectId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/isApproved?iResourceLogId=${iResourceLogId}&iIsApproved=${iIsApproved}&iEmployeeId=${iEmployeeId}&iProjectId=${iProjectId}`;
      this.http
        .put(apiURL, '')
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }


  ApprovalIndividualLogForShadowResource(RequestedLog: any, iIsApproved: any, iEmployeeId: any, iProjectId: any) {
    return this.http.put(`${environment.baseUrl}/partner/v2/isShadowLogApproved?iShadowResourceLogId=${RequestedLog}&iIsApproved=${iIsApproved}&iEmployeeId=${iEmployeeId}&iProjectId=${iProjectId}`,'');
  }

  GetAllResourceLogs(RequestedLog: any, filterFlag: any, SearchValue: any,page?:any,limit?:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/getAllResourceLogs?RequestedLog=${RequestedLog}&SearchValueFlag=${filterFlag}&SearchValue=${SearchValue}&page=${page}&limit=${limit}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAllShadowResourceLogs(RequestedLog: any, filterFlag: any, SearchValue: any,page?:any,limit?:any) {
    return this.http.get(`${environment.baseUrl}/partner/v2/getAllShadowResourceLogs?ShadowResourceRequestedLog=${RequestedLog}&SearchValueFlag=${filterFlag}&SearchValue=${SearchValue}&page=${page}&limit=${limit}`);
  }

  GetAllResourceLogsByProjectId(RequestedLog: any, filterFlag: any, SearchValue: any,iProjectId:any,page?:any,limit?:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/resource-logs-by-project?RequestedLog=${RequestedLog}&SearchValueFlag=${filterFlag}&SearchValue=${SearchValue}&iProjectId=${iProjectId}&page=${page}&limit=${limit}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAllShadowResourceLogsByProjectId(RequestedLog: any, filterFlag: any, SearchValue: any,iProjectId:any , page?:any,limit?:any) {
    return this.http.get(`${environment.baseUrl}/partner/v2/shadow-resource-logs-by-project?ShadowResourceRequestedLog=${RequestedLog}&SearchValueFlag=${filterFlag}&SearchValue=${SearchValue}&iProjectId=${iProjectId}&page=${page}&limit=${limit}`);
  }
  
  GetProjectForTimeSheet(page?:any,limit?:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/get-projects?page=${page}&limit=${limit}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetResourceLogDataForTimeSheet(iProjectId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/projectResourceData?iProjectId=${iProjectId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  GetShadowResourceLogDataForTimeSheet(iProjectId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/projectShadowResourceData?iProjectId=${iProjectId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  

  GetShadowResourceTimeSheetTableData(iProjectId: any, StartDate: any, EndDate: any, SortBy: any, SortOrder: any, iEmployeeId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/shadowResourceData?iProjectId=${iProjectId}&StartDate=${StartDate}&EndDate=${EndDate}&SortBy=${SortBy}&SortOrder=${SortOrder}&employeeBy=${iEmployeeId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  GetResourceTimeSheetTableData(iProjectId: any, StartDate: any, EndDate: any, SortBy: any, SortOrder: any, iEmployeeId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/resourceData?iProjectId=${iProjectId}&StartDate=${StartDate}&EndDate=${EndDate}&SortBy=${SortBy}&SortOrder=${SortOrder}&employeeBy=${iEmployeeId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAllTickets(tiTicketStatus: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/get-ticket?tiTicketStatus=${tiTicketStatus}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAllTicketsByProjectId(tiTicketStatus: any,iProjectId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/get-ticket-by-project?tiTicketStatus=${tiTicketStatus}&iProjectId=${iProjectId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetTicketById(ticketId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/get-ticket-by-id/${ticketId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetProjectWiseCount(iProjectId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/project-wise-count?iProjectId=${iProjectId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  DeleteShadowWorkLog(worklog_id: any) {
    return this.http.put(`${environment.baseUrl}/partner/v2/delete-shadow-resource-worklog/${worklog_id}`,'');
  }

  DeleteWorkLog(worklog_id: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/delete-worklog/${worklog_id}`;
      this.http
        .put(apiURL, '')
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  DeleteTaskStatus(data: any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/delete-pms-task-status`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  AddNewTaskStatus(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/add-task-status`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  EditTicketStatus(id: any, data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/update-ticket-status/${id}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  AddTicketComment(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/common/v2/add-pms-comment`;

      const files = [...data.vCommentAttachment];
      // create a FormData object
      const formData = new FormData();
      // Append each file to the FormData object with the key "vCommentAttachment[]"
      for (let i = 0; i < files.length; i++) {
        formData.append(`vCommentAttachment${i}`, files[i]);
      }
      formData.append('iSupportTicketId', data.iSupportTicketId);
      formData.append('iProjectId', data.iProjectId);
      formData.append('type', data.type);
      formData.append('txTicketComment', data.txTicketComment);
      formData.append('mentionedUsers',JSON.stringify(data.mentionedUsers));
      
      this.http
        .post(apiURL, formData)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  AddTicket(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/partner/v2/ticket`;

      const files = [...data.vTicketAttachment];
      // create a FormData object
      const formData = new FormData();
      // Append each file to the FormData object with the key "vTicketAttachment[]"
      for (let i = 0; i < files.length; i++) {
        formData.append(`vTicketAttachment${i}`, files[i]);
      }
      formData.append("tifileLocation", data.tifileLocation);
      formData.append("tiDisplayStatus", data.tiDisplayStatus);
      formData.append('iProjectId', data.iProjectId);
      formData.append('tiCategory', data.tiCategory);
      formData.append('tiPriority', data.tiPriority);
      formData.append('vTicketTitle', data.vTicketTitle);
      formData.append('txTicketComment', data.txTicketComment);
      formData.append('vResponsible', JSON.stringify(data.vResponsiblePerson));

      this.http
        .post(apiURL, formData)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetAllTicketComments(ticketId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/get-all-pms-comments/${ticketId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetStackHolderList() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/get-stackholder`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UpdateStackHolder(id: any, data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/updateStackHolder/${id}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  getTaskStatusByProjectId(id: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/getAllTaskStatus?iProjectId=${id}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UpdateTaskStatusTitle(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/updateTaskStatusTitle`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  UpdateTaskComment(data: any, iTaskCommentId: any) {
    console.log(data, iTaskCommentId);
    
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/update-pms-task-comment/${iTaskCommentId}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  UpdateTaskStatusOrder(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/updateTaskStatusOrder`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }
  

  GetAllBillingTableData(flag:any, SortBy: any, SortOrder: any,iProjectId?: any) {
    if(!iProjectId){
      iProjectId='';
    }
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/getAllPmsBilling?flag=${flag}&iProjectId=${iProjectId}&SortBy=${SortBy}&SortOrder=${SortOrder}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetMileStoneListByProject(iProjectId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/getMilestoneList?iProjectId=${iProjectId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetPmsInvoiceData(iProjectId: any , iPmsBillingId : any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/getClientPmsDetails?iProjectId=${iProjectId}&iPmsBillingId=${iPmsBillingId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetPmsInvoiceActivityData(iProjectId: any , iPmsBillingId : any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/getActivitiesPmsById?iProjectId=${iProjectId}&iPmsBillingId=${iPmsBillingId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  AddActivityInPmsInvoice(data:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/createActivities`;
      this.http
        .post(apiURL,data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  EditActivityInPmsInvoice(data:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/updateActivities/${data.iPmsBillingActivityId}?iProjectId=${data.iProjectId}&iPmsBillingId=${data.iPmsBillingId}`;
      this.http
        .put(apiURL,data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  DeleteActivityInPmsInvoice(data:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/deleteActivities/${data.iPmsBillingActivityId}?iProjectId=${data.iProjectId}&iPmsBillingId=${data.iPmsBillingId}`;
      this.http
        .put(apiURL,data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  UpdateClientDetailsInInvoice(data:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/updateInvoice?iProjectId=${data.iProjectId}&iPmsBillingId=${data.iPmsBillingId}`;
      this.http
        .put(apiURL,data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  
  GeneratePMSBillingInvoice(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/create-pms-bill`;
      this.http
        .post(apiURL,data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  GetInvoiceDataById(iProjectId: any , iPmsBillingId : any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/getInvoicePmsById?iProjectId=${iProjectId}&iPmsBillingId=${iPmsBillingId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetPmsBillingComments(iPmsBillingId : any,iProjectId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/get-pms-billing-comments/${iPmsBillingId}?iProjectId=${iProjectId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  AddPmsBillingComment(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/common/v2/add-pms-billing-comment`;

      const files = [...data.vCommentAttachment];
      // create a FormData object
      const formData = new FormData();
      // Append each file to the FormData object with the key "vCommentAttachment[]"
      for (let i = 0; i < files.length; i++) {
        formData.append(`vCommentAttachment${i}`, files[i]);
      }
      formData.append('iPmsBillingId', data.iPmsBillingId);
      formData.append('iProjectId', data.iProjectId);
      formData.append('type', data.type);
      formData.append('txTicketComment', data.txTicketComment);
      formData.append('mentionedUsers',JSON.stringify(data.mentionedUsers));
      this.http
        .post(apiURL, formData)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  UpdateStatusInInvoice(data:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/update-pms-billing-status?iProjectId=${data.iProjectId}&iPmsBillingId=${data.iPmsBillingId}`;
      const formData = new FormData();
      this.http
        .put(apiURL,data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  ApprovePmsInvoice(data:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/update-pms-billing-status?iProjectId=${data.iProjectId}&iPmsBillingId=${data.iPmsBillingId}`;
      const formData = new FormData();
      formData.append('pdfFile', new Blob([data.pdfBuffer], { type: 'application/pdf' }), 'your_filename.pdf');
      formData.append("iPmsBillingId", data.iPmsBillingId);
      formData.append("iProjectId", data.iProjectId);
      formData.append('tiPmsBillingStatus', data.tiPmsBillingStatus);
      this.http
        .put(apiURL,formData)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  SendMailInInvoice(data:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/send-billing-email`;
      const formData = new FormData();
      formData.append('pdfFile', new Blob([data.pdfBuffer], { type: 'application/pdf' }), 'your_filename.pdf');
      formData.append("sendToArray", JSON.stringify(data.sendToArray));
      formData.append("sendCCArray", JSON.stringify(data.sendCCArray));
      formData.append("subject", data.subject);
      formData.append("mailBody", data.mailBody);
      formData.append("vProjectName", data.vProjectName);
      this.http
        .post(apiURL,formData)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  EditDMSLink(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/partner/v2/update-dms-link`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetAllParticipant(iProjectId:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/partner/v2/get-project-participants?iProjectId=${iProjectId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  AddValueComment(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/common/v2/add-pms-value-comment`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetAllValueComments(projectId: any, iValueAddition: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/get-pms-value-comment?iProjectId=${projectId}&iValueAddition=${iValueAddition}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  UpdateValueComment(data: any, iValueCommentId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/update-pms-value-comment/${iValueCommentId}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  DeleteValueComment(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/delete-pms-value-comment`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetResponsiblePerson(iProjectId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/get-pms-resposible?iProjectId=${iProjectId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAllMembersForTag() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/get-tag-member-info`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetAssigneeListInHelpdesk(iProjectId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/get-pms-helpdesk-resposible?iProjectId=${iProjectId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  TransferAssigneeForHelpdesk(data: any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/help-desk-data-transfer`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
}

GetAllTicketsOfAssigned(iProjectId?:any) {
  if(!iProjectId){
    iProjectId='';
  }
  const promise = new Promise((resolve, reject) => {
    let apiURL = environment.baseUrl + `/partner/v2/get-assigned-tickets?&iProjectId=${iProjectId}`;
    this.http
      .get(apiURL)
      .toPromise()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
}

  getFeatureList(iProjectId: any, iMilestoneId: any) {
    return this.http.get(`${environment.baseUrl}/partner/v2/get-all-master-feature?iProjectId=${iProjectId}&iMilestoneId=${iMilestoneId}`);
  }

  addFeatureList(addFeaturedata:any) {
    return this.http.post(`${environment.baseUrl}/partner/v2/master-feature`,addFeaturedata);
  }

  updateFeature(iMasterFeatureId: any, updateFeatureData: any) {
    return this.http.put(`${environment.baseUrl}/partner/v2/update-master-feature?iMasterFeatureId=${iMasterFeatureId}`, updateFeatureData);
  }

  deleteFeature(iProjectId:any, iMasterFeatureId: any, iMileStoneId:any) {
    return this.http.delete(`${environment.baseUrl}/partner/v2/delete-master-feature?iProjectId=${iProjectId}&iMasterFeatureId=${iMasterFeatureId}&iMileStoneId=${iMileStoneId}`);
  }


  getSprintList(iProjectId: any) {
    return this.http.get(`${environment.baseUrl}/partner/v2/get-all-sprint?iProjectId=${iProjectId}`);
  }


  addSprintList(addSpeintdata:any) {
    return this.http.post(`${environment.baseUrl}/partner/v2/sprint`,addSpeintdata);
  }

  updateSprint(selectedSprintId: any, updateSprintData: any) {
    return this.http.put(`${environment.baseUrl}/partner/v2/update-sprint?iSprintId=${selectedSprintId}`, updateSprintData);
  }

  deleteSprint(selectedSprintId:any,iProjectId:any) {
    return this.http.delete(`${environment.baseUrl}/partner/v2/delete-sprint?iSprintId=${selectedSprintId}&iProjectId=${iProjectId}`);
  }

  deleteShadowResource(iSResourceTaskId: any) {
    return this.http.put(`${environment.baseUrl}/partner/v2/delete-shadow-resource/${iSResourceTaskId}`,'');
  }
}
